<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import loadSections from "@/mixins/loadSections";
import firebaseBindOrder from "@/mixins/firebase/bindOrder";

export default {
  name: "MenuOrderDetailsPayment",

  metaInfo: { title: "Menu Order Details Payment" },

  extends: View,

  mixins: [
    firebaseBindOrder,
    loadSections([
      "layout-header-menu-with-return-button-and-title",
      "public-menu-order-details",
      "public-menu-order-details-payment-tip-selector",
      "public-menu-order-details-payment-available-methods",
      "layout-footer-menu-order-details-payment",
    ]),
  ],
};
</script>
